import { RequestStatus } from '../constants/apiStatus';
import { RequestState } from '../contexts/ApiContext';

export interface CacheOptions {
  keepAliveUntil?: Date;
  keepAliveFor?: number;
}

export interface CacheCheckOptions {
  youngerThan?: number;
  laterThan?: Date;
}

export function calculateCacheExpiry(options?: CacheOptions): number | undefined {
  if (!options) return undefined;

  if (options.keepAliveUntil) {
    return options.keepAliveUntil.getTime();
  }

  if (options.keepAliveFor) {
    return Date.now() + options.keepAliveFor;
  }

  return undefined;
}

export function isCacheValid(
  request: RequestState | undefined,
  options?: CacheCheckOptions
): boolean {
  if (!request || request.status !== RequestStatus.SUCCESS) return false;

  const now = Date.now();

  // Check if cache has expired
  if (request.cacheExpiry && now > request.cacheExpiry) {
    return false;
  }

  if (options?.youngerThan && now - request.timestamp > options.youngerThan) {
    return false;
  }

  if (options?.laterThan && request.timestamp < options.laterThan.getTime()) {
    return false;
  }

  return true;
}