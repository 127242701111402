import React, { useMemo } from 'react';
import './index.scss';
import { FaRegCopy } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next/typescript/t';
import { TabIndex } from '../../types/accessibility';

type ErrorProps = {
  error: {
    message: string;
    stack?: string;
  };
  children?: React.ReactNode;
};

export const errorTitleId = "error-message-title";

function attemptTranslatingMessage(message: string, t: TFunction<[string], undefined>) {
    const match1 = message.match(/Book (\d+) has no corresponding zip file/);
    if (match1) {
      const code = match1[1];
      const extension = parseInt(code) >= 80000 ? "A250" : "A100";
      return t("Book '{{code}}{{extension}}' has no corresponding zip file", { code, extension });
    }
    return t(message);
}

const Error: React.FC<ErrorProps> = ({ error: { message, stack }, children }) => {
  const { t } = useTranslation(["common"]);

  const tMessage = useMemo(() => {
    return attemptTranslatingMessage(message, t);
  }, [message]);

  const mailtoLink = `mailto:support@meow.ch?subject=${encodeURIComponent('Error Report')}&body=${encodeURIComponent(
    `Error: ${message}\n\nStack Trace:\n${stack ?? 'No stack trace provided'}`
  )}`;

  const copyErrorDetails = () => {
    const errorDetails = `Error: ${message}\nStack Trace:\n${stack ?? 'No stack trace provided'}`;
    navigator.clipboard.writeText(errorDetails);
    alert(t("Error details copied to clipboard."));
  };

  return (
    <div className="Error">
      <h1
        id={errorTitleId}
        className="Error__title"
        tabIndex={TabIndex.notReachableButProgramaticallyFocusable}
      >
        {`${t("Error")}: ${tMessage}`}
      </h1>
      <div className="Error__details">
        {stack && (
          <pre className="Error__details--stackTrace">
            {stack}
          </pre>
        )}
      </div>
      {children}
      {(message !== "Bad credentials" && (
        <div className="Error__actions">
          <a href={mailtoLink} className="Error__button Error__button--primary">
            {t("Report Error to Developer")}
          </a>
          <button className="Error__button Error__button--secondary" onClick={copyErrorDetails}>
            <FaRegCopy className="Error__icon Error__icon--copy" /> {t("Copy Error Details")}
          </button>
          <a href="/" className="Error__button Error__button--home">
            {t("Go to Home")}
          </a>
        </div>
      ) || null)}
    </div>
  );
};

export default Error;
