import { useCallback, useState } from 'react';
import { RequestStatus } from '../constants/apiStatus';
import { useApiContext } from '../contexts/ApiProvider';
import { l } from '../../utils/log';
import { CacheOptions } from '../utils/cache';

export enum BypassOption {
  all = 'all',
  cache = 'cache',
  none = 'none',
}

export type ExecOptions = {
  bypass?: BypassOption;
  cache?: CacheOptions;
};

export const defaultOptions: ExecOptions = {
  bypass: BypassOption.none,
  cache: {
    keepAliveFor: 5 * 60 * 1000,
  }
};

// Helper to create a unique key for requests
const createRequestKey = (fnName: string, params: any) => {
  return `${fnName}:${JSON.stringify(params)}`;
};

export default function useApi<T>(
  fn: (args: { params: any; signal: AbortSignal }) => Promise<T>,
  setDataCallback?: (data: T, cacheTimestamp: number) => void,
  callerNs = '_________'
) {
  const log = l(["useApi", callerNs]);
  const apiContext = useApiContext();
  const [requestKey, setRequestKey] = useState<string>();
  const [localStatus, setLocalStatus] = useState<RequestStatus>(RequestStatus.IDLE);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  // useEffect(() => {
  //   return () => {
  //     if (requestKey) {
  //       log("CLEANING UP requestKey:", requestKey);
  //       apiContext.clearRequest(requestKey);
  //     }
  //   };
  // }, [requestKey]);

  const exec = useCallback(async (params: any, options: ExecOptions = defaultOptions) => {
    const key = createRequestKey(fn.name, params);
    log("EXEC key:", key, "bypass:", options.bypass);
    setRequestKey(key);

    // Check for cached data when bypass is 'none'
    if (options.bypass === BypassOption.none && apiContext.hasCache(key)) {
      const { data, cache } = apiContext.getCachedData<T>(key);
      if (data) {
        log("EXEC using cached data for key:", key);
        setData(data);
        setDataCallback?.(data, cache!.timestamp);
        setLocalStatus(RequestStatus.SUCCESS);
        return;
      }
    }

    const requestState = apiContext.registerRequest(key);

    // If there's a pending request and we're not using 'all' bypass, wait for it
    if (options.bypass !== BypassOption.all && requestState.promise) {
      log("EXEC pending for key:", key);
      setLocalStatus(RequestStatus.PENDING);
      try {
        const result = await requestState.promise;
        log("EXEC succeeded for key:", key);
        setData(result);
        setDataCallback?.(result, (new Date).getTime());
        setLocalStatus(RequestStatus.SUCCESS);
        return;
      } catch (err) {
        log("EXEC failed for key:", key, err);
        setError(err instanceof Error ? err : new Error(String(err)));
        setLocalStatus(RequestStatus.ERROR);
        return;
      }
    }

    // Only abort existing request if using 'all' bypass
    if (options.bypass === BypassOption.all && requestState.abortController) {
      log("EXEC aborting existing request for bypass all key:", key);
      requestState.abortController.abort();
    }

    // Create new request
    setLocalStatus(RequestStatus.PENDING);
    const promise = fn({ params, signal: requestState.abortController.signal });
    log("EXEC created new request for key:", key);

    apiContext.updateRequestState(key, { promise });

    try {
      const result = await promise;
      log("EXEC received result for key:", key);
      setData(result);
      setDataCallback?.(result, (new Date()).getTime());
      setLocalStatus(RequestStatus.SUCCESS);
      log("EXEC caching result for key:", key);
      apiContext.cacheResult(key, result, options.cache);
    } catch (err) {
      const error = err instanceof Error ? err : new Error(String(err));
      log("EXEC encountered error for key:", key, error);
      setError(error);
      setLocalStatus(RequestStatus.ERROR);
      apiContext.updateRequestState(key, {
        status: RequestStatus.ERROR,
        error,
      });
    }
  }, [fn, setDataCallback]);

  const clearHook = useCallback(() => {
    log("CLEARING API");
    setError(null);
    setLocalStatus(RequestStatus.IDLE);
    setData(null);
  }, [requestKey]);

  const abort = useCallback(() => {
    if (requestKey) {
      log("ABORTING request for key:", requestKey);
      apiContext.clearRequest(requestKey);
    }
  }, [requestKey]);

  const clearApi = useCallback(() => {
    clearHook();
    abort();
  }, [clearHook, abort]);

  log("RENDERING with status:", localStatus, "data:", data);

  return {
    data,
    apiStatus: localStatus,
    error,
    exec,
    clearHook,
    clearApi,
    abort,
    isPending: localStatus === RequestStatus.PENDING,
    isSuccess: localStatus === RequestStatus.SUCCESS,
    isError: localStatus === RequestStatus.ERROR,
    isIdle: localStatus === RequestStatus.IDLE,
  };
}