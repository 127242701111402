import { useEffect, useMemo } from 'react';
import { LinkableElementId, LinksDisplayRegion } from '../contexts/QuickLinks/QuickLinksContext';
import { useQuickLinks } from '../contexts/QuickLinks/QuickLinksProvider';

// Type for the replaceLink configuration parameter
type ReplaceLinkParams = {
  link: {
    id: LinkableElementId;
    href: string;
    label: string;
    priority: number;
    actionPriority: number;
  };
  namespace: LinksDisplayRegion;
};

// Custom hook: useReplaceQuickLink
export const useAddQuickLink = (
  linkConfig: ReplaceLinkParams,
  modifierTest?: boolean
) => {
  const { removeLink, addLink } = useQuickLinks();

  // Memoize link configuration to avoid re-execution unless linkConfig changes
  const memoizedLinkConfig = useMemo(() => linkConfig, [linkConfig]);

  useEffect(() => {
    // Exit early if modifierTest is defined and explicitly false
    if (modifierTest === false) return;

    // Replace the old link with the new link configuration
    addLink(memoizedLinkConfig);

    // Cleanup: remove the link on unmount
    return () => {
      removeLink({
        id: memoizedLinkConfig.link.id,
        namespace: memoizedLinkConfig.namespace,
        actionPriority: memoizedLinkConfig.link.actionPriority,
      });
    };
  }, [memoizedLinkConfig, addLink, removeLink, modifierTest]);
};
