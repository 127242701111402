import { NotificationNamespace } from "../contexts/Notification/NotificationContext";

export const notification_auth_loading = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Authenticating user",
};
export const notification_auth_error = {
  namespace: NotificationNamespace.Error,
  id: "Error authenticating... Please try reloading the page",
};
export const notification_auth_fail = {
  namespace: NotificationNamespace.Error,
  id: "Unable to authenticate",
};
export const notification_auth_success = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Authentication success. You are now connected.",
};
export const notification_auth_disconnected = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "You are now logged out",
};

export const notification_books_loading = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Loading book results",
};
export const notification_books_loaded = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "N book results loaded",
};
export const notification_books_error = {
  namespace: NotificationNamespace.Error,
  id: "Oops... Please try reloading the page",
}

export const notification_book_loading = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Loading book",
};
export const notification_book_error = {
  namespace: NotificationNamespace.Error,
  id: "Oops... Please try reloading the page",
};
export const notification_book_errorAfterSuccessfulRequest = {
  namespace: NotificationNamespace.Error,
  id: "Doopsy... Please try reloading the page",
};
export const notification_book_loaded = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Book page loaded",
};

export const notification_search_already_made = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "You already made this search, modify it before searching again.",
};

export const notification_wish_added_removed_f = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Book {{code}} has been {{action}} your wishlist",
}