import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import SearchContext from "../../contexts/Search/SearchContext";
import useBookSearch from "../../hooks/useBookSearch";
import { SortCriteria } from "../../types/search";
import { useLocation, useNavigate } from "react-router-dom";
import { getSearchRouteFromParams } from "../../utils/search";
import SortingButton from "../SortingButton"; // Importing the new component
import { SortDirection, SortField, splitSortCriteria } from "../../utils/sortBy";
import { ucfirst } from "../../utils/string";

type ComponentProps = {};

function cycle<T>(options: T[], current: T): T {
  const k = options.indexOf(current);
  return options[(k + 1) % options.length];
}

function getClickSortDirection(currentSortField: SortField, currentSortDirection: SortDirection, targetField: SortField) {
  const flip = currentSortField === targetField;
  return flip
      ? cycle(Object.values(SortDirection), currentSortDirection)
      : SortDirection.asc;
}

function getClickSortCriteria(currentSortField: SortField, currentSortDirection: SortDirection, targetField: SortField) {
  const sortDirection = getClickSortDirection(currentSortField, currentSortDirection, targetField);
  const sc = `${targetField}_${sortDirection}` as SortCriteria;
  return sc;
}

const fields = [SortField.title, SortField.author, SortField.year];

const SortingControls: React.FC<ComponentProps> = ({}) => {
  const { t } = useTranslation(["common"]);
  const { books } = useBookSearch("SortingControls");
  const isBooksAvailable = books && books.length > 0;
  const { sortCriteria, page, ...rest } = useContext(SearchContext);
  const navigate = useNavigate();
  const location = useLocation();

  const isHome = !location.pathname.includes('/search');
  const { sortField, sortDirection } = splitSortCriteria(isHome, sortCriteria);
  const disabled = !isBooksAvailable;

  return (
    <div className="SortingControls" role="group" aria-labelledby="sorting-controls-id">
      <h3 id="sorting-controls-id" className="SortingControls__Title" aria-hidden={true}>{t("Sorting options")}</h3>
      <div className="SortingControls__Filters">
        {fields.map(f => (
          <SortingButton
            key={f}
            label={ucfirst(t(f))}
            active={sortField === f}
            asc={sortField !== f || sortDirection === SortDirection.asc}
            disabled={disabled}
            onClick={() => navigate(getSearchRouteFromParams({
              ...rest,
              page: 0,
              sortCriteria: getClickSortCriteria(sortField, sortDirection, f),
            }))}
            ariaLabel={t(
              `Sort by {{column}}, {{direction}} order`,
              {
                column: t(f),
                direction: t(getClickSortDirection(sortField, sortDirection, f) === SortDirection.asc
                  ? "ascending"
                  : "descending"
                ),
              }
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default SortingControls;
