import { createContext } from 'react';
import { RequestStatus } from '../constants/apiStatus';
import { CacheCheckOptions, CacheOptions } from '../utils/cache';

export interface RequestState {
  status: RequestStatus;
  promise?: Promise<any>;
  data?: any;
  error?: Error;
  timestamp: number;
  abortController: AbortController;
  cacheExpiry?: number;
}

export type CachedData<T> = { data: T; cache: { timestamp: number } } | { data?: undefined; cache?: undefined; }

export interface ApiContextType {
  requests: Map<string, RequestState>;
  registerRequest: (key: string) => RequestState;
  updateRequestState: (key: string, state: Partial<RequestState>) => void;
  clearRequest: (key: string) => void;
  cacheResult: (key: string, data: any, options?: CacheOptions) => number;
  hasCache: (key: string, options?: CacheCheckOptions) => boolean;
  getCachedData: <T>(key: string) => CachedData<T>
}

const ApiContext = createContext<ApiContextType | null>(null);

export default ApiContext;