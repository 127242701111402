import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { TabIndex } from "../../types/accessibility";
import { l } from "../../utils/log";

export type ChipProps = {
  label: string;
  'aria-label'?: string;
  onDelete: () => void;
  tabIndex?: TabIndex;
  disabled?: undefined;
} | {
  label: string;
  'aria-label'?: string;
  onDelete?: undefined;
  tabIndex?: TabIndex;
  disabled?: boolean;
};

const log = l("ResultsToolbar - Chip");

const Chip: React.FC<ChipProps> = ({ label, 'aria-label': ariaLabel,
  disabled = false, onDelete, tabIndex=TabIndex.notReachableButProgramaticallyFocusable }) => {
  const { t } = useTranslation(["common"]);
  log("tabindex", tabIndex);
  return (
    <button
      tabIndex={tabIndex}
      {
        ...(tabIndex !== TabIndex.reachablePriorityLowest
        ? { "data-toolbar-item": "true" }
        :  {})
      }
      className="Chip clickable"
      onClick={onDelete}
      aria-label={ariaLabel || `${t("Remove filter:")} ${label}`}
      aria-disabled={disabled || undefined}
    >
      <span className="Chip__Label">{label}</span>
      {(!disabled && (
        <svg
          className="Chip__DeleteIcon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path
            d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 10.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.708z"
            fill="currentColor"
          />
        </svg>
      )) || null}
    </button>
  );
}

export default Chip;
