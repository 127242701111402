import React from 'react';
import { TabIndex } from '../../../types/accessibility';

interface TabPanelProps {
  children: React.ReactNode;
  id: string;
  labelledBy: string;
  isActive: boolean;
  hasFocusableChildren: boolean;
}

export function TabPanel({ children, id, labelledBy, isActive, hasFocusableChildren = false }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      id={id}
      aria-labelledby={labelledBy}
      hidden={!isActive}
      tabIndex={
        hasFocusableChildren
          ? TabIndex.notReachableButProgramaticallyFocusable
          : TabIndex.reachablePriorityLowest
      }
    >
      {children}
    </div>
  );
}