import React, { useContext, useRef } from 'react';
import ApiContext, { CachedData, RequestState } from './ApiContext';
import { RequestStatus } from '../constants/apiStatus';
import { CacheCheckOptions, CacheOptions, calculateCacheExpiry, isCacheValid } from '../utils/cache';
import { l } from '../../utils/log';

const log = l("ApiProvider");

function ApiProvider({ children }: { children: React.ReactNode }) {
  const requestsRef = useRef(new Map<string, RequestState>());

  const registerRequest = (key: string) => {
    const existingRequest = requestsRef.current.get(key);
    if (existingRequest && existingRequest.status === RequestStatus.PENDING) {
      return existingRequest;
    }

    const newState: RequestState = {
      status: RequestStatus.PENDING,
      timestamp: Date.now(),
      abortController: new AbortController(),
    };
    requestsRef.current.set(key, newState);
    return newState;
  };

  const updateRequestState = (key: string, state: Partial<RequestState>) => {
    const currentState = requestsRef.current.get(key);
    if (currentState) {
      requestsRef.current.set(key, { ...currentState, ...state });
    }
  };

  const clearRequest = (key: string) => {
    const request = requestsRef.current.get(key);
    if (request) {
      request.abortController.abort();
      requestsRef.current.delete(key);
    }
  };

  const cacheResult = (key: string, data: any, options?: CacheOptions): number => {
    const cacheExpiry = calculateCacheExpiry(options);
    const timestamp = Date.now();
    const currentState = requestsRef.current.get(key);
    requestsRef.current.set(key, currentState ? {
      ...currentState,
        status: RequestStatus.SUCCESS,
        data,
        timestamp,
        cacheExpiry,
      } : {
        status: RequestStatus.SUCCESS,
        data,
        timestamp,
        cacheExpiry,
        abortController: new AbortController(),
      }
    );
    return timestamp;
  };

  const hasCache = (key: string, options?: CacheCheckOptions): boolean => {
    log('useApi::useBookSearch REQUESTS_REF', requestsRef.current);
    const request = requestsRef.current.get(key);
    const valid = isCacheValid(request, options);
    log('useApi::useBookSearch hasCache', key, request, valid);
    return valid;
  };

  const getCachedData = <T,>(key: string): CachedData<T> => {
    const request = requestsRef.current.get(key);
    if (request && isCacheValid(request)) {
      return {
        data: request.data as T,
        cache: { timestamp: request.timestamp },
      };
    }
    return {};
  };

  return (
    <ApiContext.Provider
      value={{
        requests: requestsRef.current,
        registerRequest,
        updateRequestState,
        clearRequest,
        cacheResult,
        hasCache,
        getCachedData,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

export function useApiContext() {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApiContext must be used within an ApiProvider');
  }
  return context;
}

export default ApiProvider;