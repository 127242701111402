import React, { forwardRef, InputHTMLAttributes, useId } from 'react';
import './index.scss';

export interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label: string;
  value?: string;
  onChange: InputHTMLAttributes<HTMLInputElement>["onChange"];
  error?: string;
  fullWidth?: boolean;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  label,
  value,
  onChange,
  error,
  fullWidth = false,
  id: propId,
  className = '',
  type = 'text',
  ...props
}, ref) => {
  const generatedId = useId();
  const id = propId || `text-input-${generatedId}`;
  const errorId = `${id}-error`;

  return (
    <div
      className={`text-input ${fullWidth ? 'text-input--full-width' : ''} ${className}`}
      data-error={!!error}
    >
      <div className="text-input__wrapper">
        <input
          {...props}
          ref={ref}
          id={id}
          type={type}
          className="text-input__field"
          value={value}
          onChange={onChange}
          aria-invalid={!!error}
          aria-describedby={error ? errorId : undefined}
          placeholder=" "
        />
        <label
          htmlFor={id}
          className="text-input__label"
        >
          {label}
        </label>
      </div>
      {error && (
        <div
          id={errorId}
          className="text-input__error"
          role="alert"
        >
          {error}
        </div>
      )}
    </div>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;