import React, { useState, useCallback, useMemo } from "react";
import { AddRemove, ErrorContext, ErrorSource, ErrorToAction, SourcedErrors, TimedError } from "./ErrorContext";
import { l } from "../../utils/log";

interface GenresProviderProps {
  children: React.ReactNode;
}

const removeAt = <T extends any>(list: T[], idx: number) => (
  (list.length > 0 && [...list.slice(0, idx), ...list.slice(idx + 1)]) || []
);

const log = l('ErrorProvider');

export function sameNotUndefinedTimedErrors(
  a: TimedError | undefined,
  b: TimedError | undefined
): a is TimedError {
  if (typeof a !== typeof b) return false;
  return a?.date.getTime() === b?.date.getTime()
    && a?.error.message === b?.error.message;
}

const addRemoveSourcedTimedErrorGen = (
  setSourcedErrors: React.Dispatch<React.SetStateAction<SourcedErrors>>
) => (
  source: ErrorSource,
  timedError: TimedError | null,
  action: AddRemove
) => {
  setSourcedErrors((prevState) => {
    const { [source]: errorsFromSource, ...otherSources } = prevState;
    switch (action) {
      case AddRemove.add:
        if (timedError === null) {
          throw new Error("Bug cannot add null timed error");
        }
        const hasErrorInSource = Array.isArray(errorsFromSource)
          ? errorsFromSource.findIndex(
              (te) => sameNotUndefinedTimedErrors(te, timedError)
            ) !== -1
          : false;
        if (hasErrorInSource) return prevState;
        return {
          ...otherSources,
          [source]: [...(errorsFromSource || []), timedError] // LILO
        };
      case AddRemove.remove:
      default: // AddRemove.remove
        if (!errorsFromSource || errorsFromSource.length <= 0) return prevState;
        const errorIndexInSource = timedError === null
          ? 0 // means: remove first in list
          : errorsFromSource.findIndex(
            (te) => sameNotUndefinedTimedErrors(te, timedError)
          );
        if (errorIndexInSource === -1) return prevState;
        return {
          ...otherSources,
          [source]: removeAt(errorsFromSource, errorIndexInSource),
        };
    }
  });
};

function ErrorProvider({ children }: GenresProviderProps) {
  const [sourcedErrors, setSourcedErrors] = useState<SourcedErrors>({});
  const [shownErrorStack, setShownErrorStack] = useState<ErrorToAction[]>([]);

  const addOrRemoveSourcedError = useCallback((
    source: ErrorSource,
    timedError: TimedError | null,
    action: AddRemove
  ) => {
    return addRemoveSourcedTimedErrorGen(setSourcedErrors)(
      source,
      timedError,
      action
    );
  }, [setSourcedErrors]);

  const show = useCallback((err: ErrorToAction) => {
    addOrRemoveSourcedError(err.source, err.timedError, AddRemove.remove);
    setShownErrorStack((prevState) => [err, ...prevState]);
  }, [setShownErrorStack, addOrRemoveSourcedError]);

  const dismissFirstFromSource = useCallback(
    (source: ErrorSource) => setShownErrorStack((prevState) => {
      const idx = prevState.findIndex((eta) => eta.source === source);
      if (idx === -1) return prevState;
      return removeAt(prevState, idx);
    }),
    [setShownErrorStack]
  );

  const errorContextValue = useMemo(() => {
    return {
      sourcedErrors,
      shownErrorStack,
      addOrRemoveSourcedError,
      removeFirstFromSource: (source: ErrorSource) => addOrRemoveSourcedError(source, null, AddRemove.remove),
      show,
      dismissFirstFromSource,
    };
  }, [
    sourcedErrors,
    shownErrorStack,
    addOrRemoveSourcedError,
    show,
    dismissFirstFromSource
  ]);

  log('errorContextValue', errorContextValue.sourcedErrors);

  return (
    <ErrorContext.Provider value={errorContextValue}>
      {children}
    </ErrorContext.Provider>
  );
}

export default ErrorProvider;
