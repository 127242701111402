import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorContext, ErrorToAction } from "src/contexts/Error/ErrorContext";
import "./index.scss";
import { l } from "../../utils/log";
import Error, { errorTitleId } from "../../components/Error";
import { TabIndex } from "../../types/accessibility";

const log = l("ErrorModal");

function ErrorModal() {
  const { shownErrorStack, dismissFirstFromSource } = useContext(ErrorContext);
  const { t } = useTranslation(["common"]);
  const [dismissedError, setDismissedError] = useState<ErrorToAction | null>(null);
  const [shownError] = shownErrorStack;
  // const ref = useRef<HTMLDivElement>(null);

  log("showErrorStack", shownErrorStack);

  useEffect(() => {
    if (dismissedError === null) {
      return;
    }
    if (dismissedError.dismissAction) {
      dismissedError.dismissAction();
    }
    setDismissedError(null);
  }, [dismissedError]);

  const handleDismiss = useCallback(() => {
    setDismissedError(shownError);
    dismissFirstFromSource(shownError.source);
  }, [shownError]);

  useEffect(() => {
    if (!shownError) return;
    log("SHOWN", shownError);
    const id = setTimeout(() => {
      window.document.getElementById(errorTitleId)?.focus();
    }, 300);
    return () => clearTimeout(id);
  }, [shownError]);

  return (shownError && (
    <div className="ErrorModal__Overlay">
      <div
        // ref={ref}
        className="ErrorModal__Container"
        aria-labelledby={errorTitleId}
        tabIndex={TabIndex.reacahblePriorityHighest}
      >
        <div className="ErrorModal__Header">
          <button
            aria-hidden="true"
            className="ErrorModal__CloseButton"
            onClick={handleDismiss}
            tabIndex={TabIndex.notReachableButProgramaticallyFocusable}
          >
            ×
          </button>
        </div>
        <Error error={shownError}>
          <button
            className="ErrorModal__ActionButton ErrorModal__ActionButton--dismiss"
            onClick={handleDismiss}
          >
            {t(shownError.message === "Bad credentials"
              ? "Back to login input"
              : "Dismiss"
            )}
          </button>
        </Error>
      </div>
    </div>
  )) || null;
}

export default ErrorModal;
