import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Pagination, RemoteServiceId } from "../../api/implementations/types";
import useResult, { AuthStyle } from "../../hooks/useResult";
import { getNewWishes } from "../../api/implementations/booksApi";
import Books from "../Books";
import { UserContext } from "../../contexts/User/UserContext";
import { useTranslation } from "react-i18next";
import { l } from "../../utils/log";
import { Book } from "../../types/book";
import { getBookCardId } from "../BookCard";
import { TimedError, ErrorSource } from "../../contexts/Error/ErrorContext";
import useErrorManager from "../../hooks/useErrorManager";
import errorDsiplayHandleGen from "../../utils/errorHandler";

const paginationsAreSame = (a: Pagination | null, b: Pagination | null) => {
  return (
    (a === null && b === null) ||
    (b !== null && a !== null && a.start === b.start && a.count === b.count)
  );
};

const filterUnwished = (wishes: Book[], filterOutCodes: CodeOriginId[]) => {
  return wishes.filter(
    (wish) =>
      -1 ===
      filterOutCodes.findIndex(
        ({ code, origin }) => wish.code === code && wish.origin === origin
      )
  );
};

type CodeOriginId = { code: string; origin: RemoteServiceId };

const showCount = 12;

const log = l("WishItemList");

const WishItemList: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const smartFetchCount = showCount + 1;
  const {
    params,
    books: items,
    invalidated,
    requestIfDifferentParams: setParams,
    isPending,
    apiStatus,
    attemptRecoveryFromError,
  } = useResult(getNewWishes, "books", paginationsAreSame, AuthStyle.NO_AUTH_NULLIFY, {
    start: 0,
    count: smartFetchCount,
  });

  const makeErrorToAction = useCallback((props: {
    dismiss: () => void,
    timedError: TimedError;
    source: ErrorSource;
  }) => {
    return errorDsiplayHandleGen({ attemptRecoveryFromError })(props)
   }, [attemptRecoveryFromError]);

  useErrorManager(ErrorSource.Book, makeErrorToAction);

  const [filterOutCodes, setFilterOutCodes] = useState<CodeOriginId[]>([]);
  const [cumulatedItems, setCumulatedItems] = useState<Book[] | null>(null);
  const start = params?.start || 0;

  const [nextRequestWillReturnEmpty, setNextRequestWillReturnEmpty] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (items === null) {
      if (invalidated) {
        setCumulatedItems(null);
      }
      return;
    }
    if (items.length <= showCount) {
      setNextRequestWillReturnEmpty(true);
    }

    setCumulatedItems((prevState) => {
      const prevItems = prevState !== null ? prevState : [];
      const newItems = items.slice(0, showCount);
      const uniqueNewItems = newItems.filter(
        (newItem) => !prevItems.some((existingItem) => existingItem.code === newItem.code && existingItem.origin === newItem.origin)
      );
      return filterUnwished([...prevItems, ...uniqueNewItems], filterOutCodes);
    });

  }, [items, invalidated, filterOutCodes]);

  log("Cum", cumulatedItems);

  const filtered = useMemo(
    () => (cumulatedItems ? filterUnwished(cumulatedItems, filterOutCodes) : []),
    [cumulatedItems, filterOutCodes]
  );

  log("Filtered", filtered);

  return (
    <section className="WishItemList">
      <Books
        title={t("Wishes")}
        modifier="wishlist"
        noBooksMessage={user
          ? t("No wishes yet. Whenever you add books to your wishlist, they will show up here.")
          : `${t("Your wishes")} ${t("will show when you log in.")}`}
        books={filtered.map((w) => ({ ...w, wished: true }))}
        isPending={Boolean(isPending || (!cumulatedItems && user))}
        apiStatus={apiStatus}
        onWishChange={({ nextIsWished, ...identifier }) => {
          // we are doing something funky here, we need to place the focus before
          // we remove the currently focused element, otherwise by the time
            log("on wish changed ");
          if (nextIsWished) return;
          setTimeout(() => {
            requestAnimationFrame(() => {
              const nextBookInList = filtered.find((_, index, arr) => Boolean(arr[index - 1]?.code === identifier.code));
              const nextBook = !nextBookInList
                ? (filtered.length > 1
                  ? filtered[filtered.length -2]
                  : undefined
                )
                : nextBookInList;
                log("nextBook in list ", nextBookInList);
              if (!nextBook) {
                log("NO next book in list");
                return;
              }
              const nextBookTitleElement = window.document.querySelector<HTMLHeadingElement>(`#${getBookCardId(nextBook)} h3>a`)
                log("next book in list el", nextBookTitleElement);
              nextBookTitleElement?.focus()
            });
            setFilterOutCodes((prevState) => [...prevState, identifier])
          }, 4000);
        }}
        containerId={"wishlist-main-section"}
      />
      {(cumulatedItems && (
        !nextRequestWillReturnEmpty ? (
          <button
            className="WishItemList__More"
            onClick={() => {
              setParams({
                start: start + showCount,
                count: smartFetchCount, // fetch one more to know if more
              });
            }}
          >
            {`${t("Load more")} ${t("wishes")}`}
          </button>
        ) : (
          <p className="WishItemList__EOList">
            ... {`${t("End of your")} ${t("wishlist")}`}
          </p>
        )
      )) || null}
    </section>
  );
};

export default WishItemList;
