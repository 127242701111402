import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { searchByGen } from "../../../utils/search";
import { I18NLabelHandle } from "../../../types/search";
import "./index.scss";
import { TabIndex } from "../../../types/accessibility";
import { useTranslation } from "react-i18next";
import { l } from "../../../utils/log";

interface Fields {
  labelI18NHandle: I18NLabelHandle;
  value?: string|string[];
  underlined?: boolean;
  searchValue?: string;
}

function makeValueSearchValuePairs(searchValues: string[], values: string[], labelI18NHandle: string) {
  if (searchValues.length <= 0 && values.length <= 0) {
    throw new Error(`Missing value or searchValue for ${labelI18NHandle}`);
  } else if (searchValues.length <= 0) {
    return values.map(el => ({ searchValue: el, value: el }));
  } else if (values.length <= 0) {
    return searchValues.map(el => ({ searchValue: el, value: el }));
  } else if (searchValues.length !== values.length) {
    throw new Error(`Arrays must be same length or one empty in: ${labelI18NHandle}`);
  } else {
    return searchValues.map((searchValue, i) => ({ searchValue, value: values[i]}));
  }
}

const log = l("BookInfoField");

export default function BookInfoField({
  labelI18NHandle,
  value,
  searchValue,
  underlined,
}: Fields): JSX.Element {
  const navigate = useNavigate();
  const sureArraySearchValue = Array.isArray(searchValue) ? searchValue : (searchValue ? [searchValue] : []);
  const sureArrayValue = Array.isArray(value) ? value : (value ? [value] : []);
  log("value", value, "searchValue", searchValue, labelI18NHandle);
  const { t } = useTranslation(["common"]);
  const sureArray = useMemo(() => makeValueSearchValuePairs(sureArraySearchValue, sureArrayValue, t(labelI18NHandle)), [value, searchValue, labelI18NHandle]);
  const dataKey = `${t(labelI18NHandle)} ${value === undefined || value.length === 0 ? "" : ": "}`;
  return (
    <p className="BookInfoField__Container">
      <span className="BookInfoField__Label" aria-hidden={!!underlined}>
        {dataKey}
      </span>
      {sureArray.map(({ searchValue: term, value}, index, arr) => (
        <React.Fragment key={term}>
          <span
            className={`BookInfoField__Value${underlined ? " BookInfoField__Value--underlined clickable" : ""}`}
            role={underlined ? "link" : undefined}
            tabIndex={underlined ? TabIndex.reachablePriorityLowest : undefined}
            aria-hidden="false"
            onClick={(e) => {
              if (underlined && term) {
                e.currentTarget.blur();
                searchByGen(navigate)(labelI18NHandle, term);
              }
            }}
            onKeyDown={(e) => {
              if (underlined && term && (e.key === 'Enter' || e.key === ' ')) {
                e.preventDefault();
                e.currentTarget.blur();
                searchByGen(navigate)(labelI18NHandle, term);
              }
            }}
            aria-label={underlined ? `${dataKey} ${value}` : undefined}
          >
            {value}
          </span>
          {index < arr.length - 1 ? <span aria-hidden="true">,&nbsp;</span> : null}
        </React.Fragment >
      ))}
    </p>
  );
}