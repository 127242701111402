import React from 'react';

interface PaginationInfoProps {
  currentPage: number;
  totalPages: number;
  inToolbox?: boolean;
  t: (key: string) => string;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  totalPages,
  inToolbox = false,
  t
}) => {
  return (
    <p
      aria-hidden={inToolbox || undefined }
      className="Pagination__Info"
    >
      {t("Page")} <span role="presentation" className="Pagination__Info--page-current">{currentPage}</span> {t("of")} <span role="presentation" className="Pagination__Info--page">{totalPages}</span>
    </p>
  );
};