// NotificationContext.tsx
import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  NotificationPriority,
  Notification,
  NotificationContext,
  AddNotificationParam,
  NotificationIdentifier,
  TimeToSpeakProp,
  IdProp,
  NotificationType,
} from './NotificationContext';
import { splitArray } from '../../utils/splitArray';
import { l } from '../../utils/log';

const log = l('NotificationsProvider');

const computeTimeToSpeak = (message: string) => {
  const wordsPerMinute = 60; // average speaking rate for VoiceOver
  const words = message.split(' ').length;
  const speakForMilliseconds = (words / wordsPerMinute) * 60 * 1000;
  log("speakForMilliseconds", speakForMilliseconds);
  return speakForMilliseconds; // convert to milliseconds
};

const addProps = <T extends AddNotificationParam>(n: T): T & TimeToSpeakProp & IdProp & { key: number; } => {
  return {
    ...n,
    timeToSpeak: computeTimeToSpeak(n.message),
    id: n.id || n.message,
    key: Date.now(),
  };
};

const isInList = (notif: Notification, notifIds: NotificationIdentifier[]) => -1 !== notifIds.findIndex((n) => notif.namespace === n.namespace && notif.id === n.id)

const remove = (prevQueue: Notification[], toRemove: NotificationIdentifier[]) =>
  prevQueue.filter((notificationInPrevQueue) => !isInList(notificationInPrevQueue, toRemove)
  );

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [queue, setQueue] = useState<Notification[]>([]);
  const [currentMessage, setCurrentMessage] = useState<Notification | null>(null);

  log("QUEUE", queue);
  log("CURR MESSAGE", currentMessage);

  const removeMessages = useCallback((notifications: NotificationIdentifier[]) => {
    setQueue((prevQueue) => remove(prevQueue, notifications));
  }, []);

  const addMessages = useCallback(
    (
      notificationsToAdd: AddNotificationParam[],
      notificationsToRemove?: NotificationIdentifier[]
    ) => {
      const [toQueue, toOverride] = splitArray(
        notificationsToAdd.map(addProps),
        (n) => n.priority === NotificationPriority.Queue
      );
      log("notifToADD", notificationsToAdd);
      log("queueeed", toQueue);
      log("override", toOverride);
      setQueue((prevQueue) => {
        log('set queue --- prev', prevQueue);
        const nextQueue = (() => {
          if (toOverride.length > 0) {
            log('---- override > 0 ret', toOverride[0]);
            const notificationAlreadyQueuedIndex = prevQueue.findIndex((n) => n.id === toOverride[0].id);
            if (notificationAlreadyQueuedIndex !== -1) {
            notificationAlreadyQueuedIndex !== 0 && log("--------- DO DO 'override' : ", toOverride);
              return notificationAlreadyQueuedIndex === 0
                ?  prevQueue
                : [
                  ...prevQueue.splice(notificationAlreadyQueuedIndex, 1),
                  ...prevQueue
              ];
            }
            log("--------- DO 'override' : ", toOverride);
            return [toOverride[0]]; // Override the queue
          } else {
            const withoutRemoved = notificationsToRemove
              ? remove(prevQueue, notificationsToRemove)
              : prevQueue;
            const nextQueue = [...withoutRemoved, ...toQueue];
            log('---- override <= 0 ret', nextQueue);
            log("--------- 'withoutRemoved' : ", withoutRemoved);
            log("--------- 'NOTIF TO REMOVE' : ", notificationsToRemove);
            return nextQueue; // Add to the queue
          }
        })();
        // Only update if there's an actual difference
        if (nextQueue.length === prevQueue.length &&
            nextQueue.every((n, i) => n.id === prevQueue[i].id)) {
          return prevQueue;
        }
        return nextQueue;
      });
    },
    [setQueue]
  );

  // Process the queue whenever currentMessage is null and the queue has items
  useEffect(() => {
    log('>>>>>>>>>>>>>>>>');
    log('currentMessage', currentMessage, queue);
    if (!currentMessage && queue.length > 0) {
      log('||||||||||||| queue', queue);
      const nextMessage = queue[0];
      // If currentMessage is already the nextMessage (unlikely, but safe check), skip
      if (currentMessage === nextMessage) {
        return;
      }
      setCurrentMessage(nextMessage);
      // should do [, ...nextQueue] but finding index seems safer
      setQueue((prevQueue) => {
        log("setting curr message -- prevQueue", prevQueue);
        const nextQueue = prevQueue.filter(n => n.id !== nextMessage.id)
        // Check if filtering actually changed anything
        if (nextQueue.length === prevQueue.length) {
          return prevQueue;
        }
        log("setting curr message -- nextQueue", nextQueue);
        return nextQueue;
      });
      log('setting current message', nextMessage);
      log('SETTING CURRENT MESSAGE', nextMessage);
    }
    log('<<<<<<<<<<<<<<<<');
  }, [queue, currentMessage]);

  // Use useEffect to handle the timing and removal of the current message
  useEffect(() => {
    if (currentMessage) {
      log('starting timeout for current message', currentMessage);
      log('STARTING TIMEOUT FOR CURRENT MESSAGE', currentMessage);
      const timeoutId = setTimeout(() => {
        log('setting current message to null');
        log('NULL SETTING CURRENT MESSAGE TO NULL');
        setCurrentMessage(null);
        log('after announcement remove from queue');
        log('AFTER ANNOUNCEMENT REMOVE FROM QUEUE');
        setQueue((prevQueue) => {
          log("after remove -- prevQueue", prevQueue);
          const nextQueue = prevQueue.filter(n => n.id !== currentMessage.id)
          log("after remove -- nextQueue", nextQueue);
          return nextQueue;
        });
      }, currentMessage.timeToSpeak);

      // Clean up the timeout if currentMessage changes or the component unmounts
      return () => {
        log('clearing timeout for current message', currentMessage);
        log('CLEARING TIMEOUT FOR CURRENT MESSAGE', currentMessage);
        clearTimeout(timeoutId);
      };
    }
  }, [currentMessage]);

  return (
    <NotificationContext.Provider value={{ addMessages, removeMessages }}>
      <div
        className="visually-hidden"
        aria-live="assertive"
        aria-atomic="true"
        role="alert"
      >
        {currentMessage?.type === NotificationType.Assertive && (
          <div key={currentMessage.key}>
            {currentMessage.message}
          </div>
        )}
      </div>
      <div
        className="visually-hidden"
        aria-live="polite"
        aria-atomic="true"
        role="status"
      >
        {currentMessage?.type === NotificationType.Polite && (
          <div key={currentMessage.key}>
            {currentMessage.message}
          </div>
        )}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
