import React, { useRef, useEffect, useState, useCallback } from 'react';
import Pagination from '../Pagination';
import SearchQueryFilters from '../SearchQueryFilters';
import './index.scss';
import { useTranslation } from 'react-i18next';
import SortingControls from '../SortingControls';
import { l } from '../../utils/log';
import useBookSearch from '../../hooks/useBookSearch';

const log = l("ResultsToolbar");

type ResultsToolbarProps = {
  "aria-controls": string;
};
const handleKeyDownGen = (toolbarItems: HTMLElement[]) => (event: React.KeyboardEvent) => {
    const currentIndex = toolbarItems.findIndex(
      (item) => item === document.activeElement
    );

    if (currentIndex === -1) return; // No valid current item found

    const moveFocus = (newIndex: number) => {
      // Set old one to -1
      toolbarItems[currentIndex]?.setAttribute("tabindex", "-1");
      // Set new one to 0
      toolbarItems[newIndex]?.setAttribute("tabindex", "0");
      toolbarItems[newIndex]?.focus();
    };

    console.log("EEEEEEEK", event.key);

    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowDown': {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % toolbarItems.length;
        moveFocus(nextIndex);
        break;
      }
      case 'ArrowLeft':
      case 'ArrowUp': {
        event.preventDefault();
        const prevIndex = (currentIndex - 1 + toolbarItems.length) % toolbarItems.length;
        moveFocus(prevIndex);
        break;
      }
      case 'Home': {
        event.preventDefault();
        moveFocus(0);
        break;
      }
      case 'End': {
        event.preventDefault();
        moveFocus(toolbarItems.length - 1);
        break;
      }
      default:
        break;
    }
  }

const ResultsToolbar: React.FC<ResultsToolbarProps> = ({
  "aria-controls": ariaControls,
}) => {
  const { t } = useTranslation(['common']);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [toolbarItems, setToolbarItems] = useState<HTMLElement[]>([]);

  const {
    totalCount,
    receivedCount,
    currentBooksValuesParam,
  } = useBookSearch("ResultsToolbar");

  const resetTabIndexes = useCallback((items: HTMLElement[]) => {
    // Set all to -1 initially
    items.forEach(item => item.setAttribute('tabindex', '-1'));
    // Find the first non-disabled item
    const firstEnabledItem = items.find(
      (item) => item.getAttribute("aria-disabled") !== "true"
    );
    if (firstEnabledItem) {
      firstEnabledItem.setAttribute("tabindex", "0");
    }
  }, []);

  // On initial and subsequent renders, gather toolbar items and reset tab indices
  useEffect(() => {
    if (toolbarRef.current) {
      const items = Array.from(
        toolbarRef.current.querySelectorAll<HTMLElement>('[data-toolbar-item="true"]:not([aria-hidden="true"])')
      );
      log("current items", items);
      setToolbarItems(items);
    }
  }, [totalCount, receivedCount, currentBooksValuesParam]);

  // After toolbarItems updates, reset their tab indexes
  useEffect(() => {
    if (toolbarItems.length > 0) {
      resetTabIndexes(toolbarItems);
    }
  }, [toolbarItems, resetTabIndexes]);

  // Whenever the user leaves the toolbar (focus moves outside), reset tab indexes
  useEffect(() => {
    const handleFocusOut = (event: FocusEvent) => {
      if (toolbarRef.current && !toolbarRef.current.contains(event.relatedTarget as Node)) {
        // Focus moved completely out of the toolbar
        resetTabIndexes(toolbarItems);
      }
    };
    const el = toolbarRef.current;
    if (el) {
      el.addEventListener('focusout', handleFocusOut);
      return () => {
        el.removeEventListener('focusout', handleFocusOut);
      };
    }
  }, [toolbarItems, resetTabIndexes]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => handleKeyDownGen(toolbarItems)(event), [toolbarItems]);

  return (
    <div
      className="ResultsToolbar"
      role="toolbar"
      aria-label={`${t('Results controls')}`}
      ref={toolbarRef}
      onKeyDown={handleKeyDown}
      aria-controls={ariaControls}
    >
      <Pagination withinToolbar={true} />
      <SortingControls />
      <SearchQueryFilters />
    </div>
  );
};

export default ResultsToolbar;
