import React from "react";
import { QueryType, Format, YouthText } from "../../types/search";
import { useTranslation } from "react-i18next";
import Chip from "../Chip";
import "./index.scss";
import { useSearchQuery } from "../../contexts/SearchQuery/SearchQueryProvider";
import useGenres from "../../hooks/useGenres";
import { useNavigate } from "react-router-dom";
import { getSearchRouteFromParams } from "../../utils/search";

const SearchQueryFilters: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const { setSearchQuery, alreadySearchedSearchQuery } = useSearchQuery();
  const { genres } = useGenres();
  const navigate = useNavigate();

  const getQueryTypeChip = () => {
    if (!alreadySearchedSearchQuery.queryType || !alreadySearchedSearchQuery.queryText) return null;

    const plural = alreadySearchedSearchQuery.queryText.split(" ").length > 1;

    const label = {
      [QueryType.author]: `${t("writtenBy")} ${alreadySearchedSearchQuery.queryText}`,
      [QueryType.text]: `${t(plural ? "containingTheTerms" : "containingTheTerm")} "${alreadySearchedSearchQuery.queryText}"`,
      [QueryType.reader]: `${t("readBy")} ${alreadySearchedSearchQuery.queryText}`,
      [QueryType.title]: `${t("containingTitle")} "${alreadySearchedSearchQuery.queryText}"`,
    }[alreadySearchedSearchQuery.queryType];

    return label ? (
      <Chip
        key="queryType"
        label={label}
        onDelete={() => {
          setSearchQuery((prev) => ({
            ...prev,
            queryText: "",
            queryType: QueryType.text,
          }));
          navigate(
            getSearchRouteFromParams({
              ...alreadySearchedSearchQuery,
              queryText: undefined,
              queryType: undefined,
            })
          );
        }}
      />
    ) : null;
  };

  const getGenreChip = () => {
    if (!alreadySearchedSearchQuery.gatewayGenreCode) return null;

    const genre = genres.find(
      (genre) => genre.code === alreadySearchedSearchQuery.gatewayGenreCode
    );

    return genre ? (
      <Chip
        key="genre"
        label={`${t("inGenre")} ${genre.text}`}
        onDelete={() => {
          setSearchQuery((prev) => ({
            ...prev,
            gatewayGenreCode: undefined,
          }));
          navigate(
            getSearchRouteFromParams({
              ...alreadySearchedSearchQuery,
              gatewayGenreCode: undefined,
            })
          );
        }}
      />
    ) : null;
  };

  const getFormatChip = () => {
    if (!alreadySearchedSearchQuery.format || alreadySearchedSearchQuery.format === Format.all) return null;

    const formatLabels = {
      [Format.daisy]: `${t("inFormat")} DAISY`,
      [Format.brf]: `${t("inFormat")} BRF`,
    };

    const formatLabel = formatLabels[alreadySearchedSearchQuery.format];

    return formatLabel ? (
      <Chip
        key="format"
        label={formatLabel}
        onDelete={() => {
          setSearchQuery((prev) => ({
            ...prev,
            format: Format.all,
          }));
          navigate(
            getSearchRouteFromParams({
              ...alreadySearchedSearchQuery,
              format: Format.all,
            })
          );
        }}
      />
    ) : null;
  };

  const getYouthChip = () =>
    alreadySearchedSearchQuery.youth === YouthText.yes ? (
      <Chip
        key="youth"
        label={t("forYouth")}
        onDelete={() => {
          setSearchQuery((prev) => ({
            ...prev,
            youth: undefined,
          }));
          navigate(
            getSearchRouteFromParams({
              ...alreadySearchedSearchQuery,
              youth: undefined,
            })
          );
        }}
      />
    ) : null;

  const chips = [
    getQueryTypeChip(),
    getGenreChip(),
    getFormatChip(),
    getYouthChip(),
  ].filter(Boolean);

  return (
    <div className="SearchQueryFilters" role="group" aria-labelledby="filtres-actifs-heading">
      <h3 id="filtres-actifs-heading" className="SearchQueryFilters__Title" aria-hidden={true}>{t("Active filters")}</h3>
      <div className="SearchQueryFilters__Filters">
        {(chips.length <= 0 && (
          <Chip
            key="no-filters"
            label={t("Latest publications")}
            aria-label={t("No filters currently active, showing: {{showing}}", { showing: t("Latest publications") })}
            disabled={true}
          />
        )) || chips}
      </div>
    </div>
  );
};

export default SearchQueryFilters;
