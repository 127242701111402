import React, { useCallback, useRef } from "react";
import AdvancedSearch from "../AdvancedSearch";
import { useTranslation } from "react-i18next";
import { NewSearchProps } from "../../types/search";
import { useNavigate } from "react-router-dom";
import { getSearchRouteFromParams } from "../../utils/search";
import SearchButton from "../SearchButton";
import "./index.scss";
import { TabIndex } from "../../types/accessibility";
import { l } from "../../utils/log";
import { useNotifications } from "../../contexts/Notification/NotificationProvider";
import { notification_search_already_made } from "../../utils/notifications";
import { NotificationPriority, NotificationType } from "../../contexts/Notification/NotificationContext";
import TextInput from "../TextInput";

type ComponentProps = {
  id: string;
  searchQuery: NewSearchProps;
  setSearchQuery: React.Dispatch<React.SetStateAction<NewSearchProps>>;
  setShowAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>; showAdvancedSearch: boolean;
  alreadySearched: boolean;
}

const log = l("SearchBar");

const SearchBar: React.FC<ComponentProps> = ({
  id,
  searchQuery,
  setSearchQuery,
  setShowAdvancedSearch,
  showAdvancedSearch,
  alreadySearched,
}) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { addMessages } = useNotifications();
  const advancedSearchRef = useRef<HTMLDivElement>(null);

  const handleSearch = useCallback(() => {
    setShowAdvancedSearch(false);
    log("ALREADY _ -------- ", alreadySearched);
    if (alreadySearched) {
      log("ALREADY _ inside -------- ", alreadySearched);
      addMessages([{
        ...notification_search_already_made,
        message: t(notification_search_already_made.id),
        priority: NotificationPriority.Override,
        type: NotificationType.Assertive,
      }]);
    }
    const route = getSearchRouteFromParams({ ...searchQuery, page: 0 });
    log("SEARCH _ -------- QUERY", searchQuery, "ROUTE", route);
    navigate(route);
  }, [setShowAdvancedSearch, getSearchRouteFromParams, searchQuery, navigate, addMessages, alreadySearched, t]);

  return (
    <form
      id={id}
      className="SearchBar__Container"
      // aria-label={`${t("Search books")} ${searchQuery.queryText}`}
      onSubmit={event => {
        event.preventDefault();
      }}
      role="search"
    >
      <TextInput
        id="outlined-search"
        label={t("Searching for a book?")}
        fullWidth
        value={searchQuery.queryText}
        onChange={(e) => {
          setSearchQuery((prevState) => ({
            ...prevState,
            queryText: e.target.value,
          }));
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
            handleSearch();
          }
        }}
      />
      <SearchButton triggerSearch={handleSearch} disabled={alreadySearched} />
      <div className="Navbar__AdvancedSearchWrap">
        <button
          className="Navbar__AdvancedSearchButton clickable"
          onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
          aria-expanded={showAdvancedSearch}
          aria-controls="advanced-search-content"
          aria-label={t("Advanced Search")}
        >
          {t("Advanced Search")}
        </button>
        <div
          id="advanced-search-content"
          className="Navbar__AdvancedSearchContent"
          role="region"
          aria-hidden={!showAdvancedSearch}
          ref={advancedSearchRef}
          tabIndex={TabIndex.notReachableButProgramaticallyFocusable}
        >
          {showAdvancedSearch && (
            <AdvancedSearch
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              triggerSearch={handleSearch}
              alreadySearched={alreadySearched}
            />
          )}
        </div>
      </div>
    </form>
  );
}

export default SearchBar;